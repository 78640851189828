import React from 'react';
import Logo from '../images/Interi.png';

export const PopupComponentRaster = () => {
 
  return (
    <>
      <div className="modal-container2">
        {/* Logo or Image Element */}
        <img src={Logo} alt="Logo" className="logoRaster" />

      </div>
    </>
  );
};
